import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"

import '../styles/case-study-tile.scss';

const CaseStudyTile = ({ image, client, blurb, tags, link, type, year }) => {
    return (
        <a href={link} className="case-study-tile">
            <div className="image">
                <GatsbyImage image={image} alt={`${client} image`} />
            </div>
            <div className="content">
                <div className="meta">
                    <span className="ff-bold year">{year}</span><span className="ff slash">/</span><span className="ff">{type}</span>
                </div>
                <h3 className="ff">{client}</h3>
                <p className="ff">{blurb}</p>
            </div>
            <div className="tags">
                { tags.map(tag => {
                    return <span key={tag} className="ff tag">{tag}</span>
                })}
            </div>
        </a>
    )
};

export default CaseStudyTile;